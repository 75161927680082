import React from 'react';
import AttachedLinks, { AttachedLink } from '@components/views/AttachedLinks';
import Container from '@components/containers/Container';

export interface AttachedLinkACFProps {
  attached_links: { link: AttachedLink }[];
}

const AttachedLinksACF: React.FunctionComponent<AttachedLinkACFProps> = ({
  attached_links,
}) => {
  if (!attached_links) return null;

  const links = attached_links.map(({ link }) => link)?.filter((v) => v);

  return (
    <Container className="py-32">
      <AttachedLinks links={links} />
    </Container>
  );
};
export default AttachedLinksACF;
