import React from 'react';
import Link from 'next/link';

export interface AttachedLink {
  url: string;
  title: string;
}

export interface AttachedLinksProps {
  links: AttachedLink[];
}

const AttachedLinks: React.FunctionComponent<AttachedLinksProps> = ({
  links,
}) => {
  return (
    <ul className="flex items-center gap-24 md:gap-x-54 text-sm flex-wrap">
      <li className="font-extrabold">Powiązane linki:</li>
      {links.map((link) => (
        <li key={link.title}>
          <Link href={link.url}>
            <a className="hover:underline">{link.title}</a>
          </Link>
        </li>
      ))}
    </ul>
  );
};
export default AttachedLinks;
